import { lazy } from "react";

const LockList = lazy(() => import("./LockList"));
const LockCodeList = lazy(() => import("./LockCodeList"));
const LockCodeGenerate = lazy(() => import("./LockCodeGenerate"));

const newsRoutes = [
  {
    path: "/lock/list",
    element: <LockList />,
  },
  {
    path: "/lock/listcode",
    element: <LockCodeList />,
  },
  {
    path: "/lock/generate",
    element: <LockCodeGenerate />,
  },
];

export default newsRoutes;
