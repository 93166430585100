import { lazy } from "react";

const PropertyList = lazy(() => import("./PropertyList"));
const PropertyView = lazy(() => import("./PropertyView"));
const PropertyEdit = lazy(() => import("./PropertyEdit"));

const propertyRoutes = [
  {
    path: "/property/list",
    element: <PropertyList />,
  },
  {
    path: "/property/view",
    element: <PropertyView />,
  },
  {
    path: "/property/edit",
    element: <PropertyEdit />,
  },
];

export default propertyRoutes;
