import { lazy } from "react";

const NotificationList = lazy(() => import("./NotificationList"));
const NotificationView = lazy(() => import("./NotificationView"));

const notificationRoutes = [
  {
    path: "/notification/list",
    element: <NotificationList />,
  },
  {
    path: "/notification/view",
    element: <NotificationView />,
  },
];

export default notificationRoutes;
