import Mock from "../mock";

const propertyStatus = {
    list:
      [
        {
          id: 1,
          description: 'Setup',
          propstatusref: 'SETUP',
          available: 1,
          dead: 0,
        },
        {
          id: 2,
          description: 'Normal',
          propstatusref: 'NORMAL',
          available: 1,
          dead: 0,
        },
        {
          id: 3,
          description: 'Under Repair',
          propstatusref: 'REPAIR',
          available: 1,
          dead: 0,
        },
        {
          id: 4,
          description: 'Decommission',
          propstatusref: 'DECOM',
          available: 0,
          dead: 0,
        },
        {
          id: 5,
          description: 'Mothballed',
          propstatusref: 'MOTHBALL',
          available: 0,
          dead: 0,
        },
        {
          id: 6,
          description: 'Terminated',
          propstatusref: 'TERMINATED',
          available: 0,
          dead: 1,
        },
        {
          id: 7,
          description: 'Management only',
          propstatusref: 'MANAGEMENTONLY',
          available: 1,
          dead: 0,
        },
      ],
};

Mock.onGet("/api/propertystatus").reply(config => {
  const response = propertyStatus.list;
  return [200, response];
});
