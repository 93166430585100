import { lazy } from "react";

const ReportList = lazy(() => import("./ReportList"));
const ReportView = lazy(() => import("./ReportView"));

const reportRoutes = [
  {
    path: "/report/list",
    element: <ReportList />,
  },
  {
    path: "/report/view",
    element: <ReportView />,
  },
];

export default reportRoutes;
