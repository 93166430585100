import { lazy } from "react";

const NewsList = lazy(() => import("./NewsList"));
const NewsView = lazy(() => import("./NewsView"));

const newsRoutes = [
  {
    path: "/news/list",
    element: <NewsList />,
  },
  {
    path: "/news/view",
    element: <NewsView />,
  },
];

export default newsRoutes;
