import { NotificationContainer, NotificationManager } from 'react-notifications';

export const NotificationService = () => {
}

export const notify = (values) => {
console.log("values: ", values);
console.log("values.type: ", values.type);
console.log("values.action: ", values.action);
console.log("values.message: ", values.message);
	const action  = values.action;
	const type    = values.type;
	const message = values.message;

	switch (action) {

		case 'resetPassword':
        	if ('success' == type)
        		NotificationManager.success('Success message', 'Title here');
        	else
        		NotificationManager.error('Error message', 'Title here');

        	break;


	};

	return
		<div>
			<NotificationContainer />
		</div>
}
