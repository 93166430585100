import { lazy } from "react";

const DocumentList = lazy(() => import("./DocumentList"));
const DocumentView = lazy(() => import("./DocumentView"));

const documentRoutes = [
  {
    path: "/document/list",
    element: <DocumentList />,
  },
  {
    path: "/document/edit",
    element: <DocumentView />,
  },
];

export default documentRoutes;
