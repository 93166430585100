import { merge } from "lodash";
import { classList } from "@utils";
import ScrollBar from "react-perfect-scrollbar";
import { IconContext } from "react-icons";
import { useDispatch, useSelector } from "react-redux";
import { setLayoutSettings } from "app/redux/layout/layoutSlice";
import { NavLink } from 'react-router-dom';
import { BiBookAdd, BiEdit } from "react-icons/bi";
import { BsViewList } from "react-icons/bs";
import { GiDoorHandle } from "react-icons/gi";
import { MdOutlineList, MdOutlineCalendarMonth, MdHomeWork, MdOutlineAccountCircle, MdOutlinePayments } from "react-icons/md";

const Customizer = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.layout);

  const handleCustomizerToggle = () => {
    dispatch(
      setLayoutSettings(
        merge({}, settings, {
          customizer: {
            open: !settings.customizer.open,
          },
        })
      )
    );
  };

  return (
    <div
      id="customizer"
      className={classList({
        customizer: true,
        open: settings.customizer.open,
      })}
    >
      <div className="handle" onClick={handleCustomizerToggle}>
        <i className="i-Gear spin"></i>
      </div>
      <ScrollBar
        className="customizer-body"
        data-perfect-scrollbar
        data-suppress-scroll-x="true"
        style={{ width: "14rem" }}
      >
        <div className="accordion" id="accordionCustomizer">

          <div className="card">
            <div
              id="collapseTwo"
              className="collapse show"
              aria-labelledby="headingTwo"
              data-parent="#accordionCustomizer"
            >
              <div className="card-body" style={{maxWidth: "fit-content"}}>
                <div className="list-group">
                  <NavLink to="/booking/edit" state="{booking_id: null}" >
                    <span className="list-group-item list-group-item-action">
                      <span><IconContext.Provider value={{ size: "1rem" }}><BiBookAdd /></IconContext.Provider></span><span>&nbsp;New booking</span>
                    </span>
                  </NavLink>
                  <NavLink to="/booking/list" >
                    <span className="list-group-item list-group-item-action">
                      <span><IconContext.Provider value={{ size: "1rem" }}><BiEdit /></IconContext.Provider></span><span>&nbsp;Edit booking</span>
                    </span>
                  </NavLink>
                  <NavLink to="/booking/list">
                    <span className="list-group-item list-group-item-action">
                      <span><IconContext.Provider value={{ size: "1rem" }}><MdOutlineList /></IconContext.Provider></span><span>&nbsp;View bookings</span>
                    </span>
                  </NavLink>
                  <NavLink to="/booking/calendar">
                    <span className="list-group-item list-group-item-action">
                      <span><IconContext.Provider value={{ size: "1rem" }}><MdOutlineCalendarMonth /></IconContext.Provider></span><span>&nbsp;View calendar</span>
                    </span>
                  </NavLink>
                  <NavLink to="/lock/generate">
                    <span className="list-group-item list-group-item-action">
                      <span><IconContext.Provider value={{ size: "1rem" }}><GiDoorHandle /></IconContext.Provider></span><span>&nbsp;Get door code</span>
                    </span>
                  </NavLink>
                  <NavLink to="/account/statementmonthly">
                    <span className="list-group-item list-group-item-action">
                      <span><IconContext.Provider value={{ size: "1rem" }}><BsViewList /></IconContext.Provider></span><span>&nbsp;View statement</span>
                    </span>
                  </NavLink>
                  <NavLink to="/maintenance/edit" state="{workorder_id: null}">
                    <span className="list-group-item list-group-item-action">
                      <span><IconContext.Provider value={{ size: "1rem" }}><MdHomeWork /></IconContext.Provider></span><span>&nbsp;Request work order</span>
                    </span>
                  </NavLink>
                  <NavLink to="/account">
                    <span className="list-group-item list-group-item-action">
                      <span><IconContext.Provider value={{ size: "1rem" }}><MdOutlineAccountCircle /></IconContext.Provider></span><span>&nbsp;Update account</span>
                    </span>
                  </NavLink>
                  <NavLink to="/account/payment/create">
                    <span className="list-group-item list-group-item-action">
                      <span><IconContext.Provider value={{ size: "1rem" }}><MdOutlinePayments /></IconContext.Provider></span><span>&nbsp;Make payment</span>
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollBar>
    </div>
  );
};

export default Customizer;
