import { image_base_url, owner_booking_types } from './AppConstants';

export const getFilenameAndExtension = (pathfilename) => {

    var filenameextension = pathfilename.replace(/^.*[\\\/]/, '');
    var filename          = filenameextension.substring(0, filenameextension.lastIndexOf('.'));
    var ext               = filenameextension.split('.').pop();

    return [filename, ext];
};

// https://s3-us-west-2.amazonaws.com/vd-property-assets/PropertyAssets/d2540/images/1ea247b0-7a98-4888-9514-4b6542a8ad29.c10.vrs.w800-h533.jpg
export const getComposeFrontImagePath = (data) => {
    const [filename, extension, propertyref] = data;

    const append_path = propertyref.toLowerCase()+"/images/"
    const append_to_filename = ".vrs.w800-h533.";

    const composed = image_base_url+append_path+filename+append_to_filename+extension;

    return composed;
};

export const parseJSONWithErrorHandler = (data) => {
    try {
        return JSON.parse(data || '');
    } catch (error) {
        return [];
    }
};

export const getNights = (first_date, second_date) => {
    const date1 = new Date(first_date);
    const date2 = new Date(second_date);
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return numberOfNights;
};

/*
$now = date('Y-m-d h:i:s');
if(($bk_who == 'OWN') and ($bk['AvTo'] >= $now) and ($bk_status != 'Cancelled') and ($_SESSION['website'] == 'VDHQ')) {
    <a title='Get arrival pack' href="<?php echo $_SERVER['PHP_SELF']; ?>?oaction=properties&osub=kaba&prpID=<?php echo $bk['AvPID']; ?>&id=<?php echo $bk['AvID']; ?>&bookingtype=<?php echo $bk['AvStatus']; ?>&guestname=<?php echo $bk_gst; ?>&from=<?php echo $bk['AvFrom']; ?>&to=<?php echo $bk['AvTo']; ?>">Get Arrival pack</a></td>
  }
if ( ($bk['AvPPStatus']=='E') and ($bk_who=='OWN') and (strtotime($bk['AvFrom'])>time()) ) {
  if ($bk['AvStatus'] == 'OBTD') { ?>
    <a title='Pay outstanding tax on booking <?php echo $bk['AvID'];?>' href="<?php echo $_SERVER['PHP_SELF']; ?>?oaction=properties&osub=6&id=<?php echo $bk['AvUID']; ?>">Pay</a>&nbsp;|&nbsp;
  }
    <a title='Change booking <?php echo $bk['AvID'];?>' href="<?php echo $_SERVER['PHP_SELF']; ?>?oaction=properties&osub=5&id=<?php echo $bk['AvUID']; ?>">Edit</a>
  }
}
*/

export const getShowPrintArrivalPack = (booking) => {
    let show_print_arrival_pack = false;

    const today = new Date();
    const departure = new Date(booking.booking_departure);

    const hasGuesttypeOwner = owner_booking_types.find((type) => type.id === booking.guesttype_id);

    if (hasGuesttypeOwner && 'cancelled' !== booking.booking_bookstatus.toLowerCase() && departure >= today)
        show_print_arrival_pack = true;

    return show_print_arrival_pack;
};

// booking has not arrived, is an owner booking (guesttype owner), and has bookstatus === booking
export const getShowCancelBookingButton = (booking) => {
    let show_cancel_button = false;

    const today = new Date();
    const arrival = new Date(booking.booking_arrival);

    const hasGuesttypeOwner = owner_booking_types.find((type) => type.id === booking.guesttype_id);

    if (hasGuesttypeOwner && 'cancelled' !== booking.booking_bookstatus.toLowerCase() && arrival >= today)
        show_cancel_button = true;

    return show_cancel_button;
};

export const getQueryStringParams = () => {
  var assoc  = {};
  var decode = function (s) { return decodeURIComponent(s.replace(/\+/g, " ")); };
  var queryString = window.location.search.substring(1);
  var keyValues = queryString.split('&');

  for(var i in keyValues) {
    var key = keyValues[i].split('=');
    if (key.length > 1) {
      assoc[decode(key[0])] = decode(key[1]);
    }
  }

  return assoc;
};

export const printFromElement = (e, title = '') => {
    // the old, old-fashioned way
    // create a new window, put our element contents into it
    // open the print dialog
    var prtContent = e;
    var WinPrint = window.open('', title, 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
};

export const formatCurrency = (value) => {
    // https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return (formatter.format(value));
};

export const isOnline = () => {
    return window.navigator.onLine;
};

window.addEventListener('offline', function(e) { console.log('offline'); });
window.addEventListener('online', function(e) { console.log('online'); });
