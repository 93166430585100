import { lazy } from "react";

const PdfViewer = lazy(() => import("./PdfViewer"));

const pdfviewerRoutes = [
  {
    path: "/pdfviewer",
    element: <PdfViewer />,
  },
];

export default pdfviewerRoutes;
