import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState: {
    count: [],
    changed: false,
  },
  reducers: {
    setCount: (state, action) => { // not in use. using data/db/bookingtypes.js
      state.count = action.payload;
    },
    incrementCount: (state, action) => { // not in use. using data/db/bookingtypes.js
      state.count = state.count + action.payload;
    },
  },
});

export const notificationActions = notificationSlice.actions;

export default notificationSlice;
