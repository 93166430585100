import { lazy } from "react";

const BookingCalendar = lazy(() => import("./BookingCalendar"));
const BookingList = lazy(() => import("./BookingList"));
const BookingEdit = lazy(() => import("./BookingEdit"));
const BookingView = lazy(() => import("./BookingView"));
const ArrivalPack = lazy(() => import("./ArrivalPack"));

const bookingRoutes = [
  {
    path: "/booking/calendar",
    element: <BookingCalendar />,
  },
  {
    path: "/booking/list",
    element: <BookingList />,
  },
  {
    path: "/booking/edit/",
    element: <BookingEdit />,
  },
  {
    path: "/booking/view/",
    element: <BookingView />,
  },
  {
    path: "/booking/arrivalpack/",
    element: <ArrivalPack />,
  },
];

export default bookingRoutes;
