import { lazy } from "react";

const Weather = lazy(() => import("./Weather"));

const weatherRoutes = [
  {
    path: "/weather",
    element: <Weather />,
  },
];

export default weatherRoutes;
