import AuthGuard from "./auth/AuthGuard";
import { createBrowserRouter, redirect } from "react-router-dom";
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
// import uiKitsRoutes from "./views/ui-kits/uiKitsRoutes";
// import formsRoutes from "./views/forms/formsRoutes";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
// import widgetsRoute from "./views/widgets/widgetsRoute";
// import chartsRoute from "./views/charts/chartsRoute";
// import dataTableRoute from "./views/dataTable/dataTableRoute";
// import extraKitsRoutes from "./views/extra-kits/extraKitsRoutes";
// import pagesRoutes from "./views/pages/pagesRoutes";
// import iconsRoutes from "./views/icons/iconsRoutes";
// import invoiceRoutes from "./views/app/invoice/invoiceRoutes";
// import inboxRoutes from "./views/app/inbox/inboxRoutes";
// import chatRoutes from "./views/app/chat/chatRoutes";
// import calendarRoutes from "./views/app/calendar/calendarRoutes";
// import taskManagerRoutes from "./views/app/task-manager/taskManagerRoutes";
// import ecommerceRoutes from "./views/app/ecommerce/ecommerceRoutes";
// import contactRoutes from "./views/app/contact/contactRoutes";
import Error404 from "./views/sessions/Error";
import propertyRoutes from "./views/property/propertyRoutes";
import bookingRoutes from "./views/booking/bookingRoutes";
import documentRoutes from "./views/document/documentRoutes";
import maintenanceRoutes from "./views/maintenance/maintenanceRoutes";
import newsRoutes from "./views/news/newsRoutes";
import adminRoutes from "./views/admin/adminRoutes";
import accountRoutes from "./views/account/accountRoutes";
import reportRoutes from "./views/report/reportRoutes";
import lockRoutes from "./views/lock/lockRoutes";
import weatherRoutes from "./views/weather/weatherRoutes";
import notificationRoutes from "./views/notification/notificationRoutes";
import pdfviewerRoutes from "./views/app/pdfviewer/pdfviewerRoutes";

const routes = createBrowserRouter([
  {
    element: <AuthGuard />,
    children: [
      ...dashboardRoutes,
      // ...uiKitsRoutes,
      // ...formsRoutes,
      // ...widgetsRoute,
      // ...chartsRoute,
      // ...dataTableRoute,
      // ...extraKitsRoutes,
      // ...pagesRoutes,
      // ...iconsRoutes,
      // ...invoiceRoutes,
      // ...inboxRoutes,
      // ...calendarRoutes,
      // ...taskManagerRoutes,
      // ...ecommerceRoutes,
      // ...contactRoutes,
      // ...chatRoutes,
      ...propertyRoutes,
      ...bookingRoutes,
      ...documentRoutes,
      ...maintenanceRoutes,
      ...newsRoutes,
      ...adminRoutes,
      ...accountRoutes,
      ...reportRoutes,
      ...lockRoutes,
      ...weatherRoutes,
      ...notificationRoutes,
      ...pdfviewerRoutes,
    ],
  },
  ...sessionsRoutes,
  {
    path: "/",
    exact: true,
    loader: () => redirect("/dashboard/"),
  },
  {
    path: "*",
    element: <Error404 />,
  },
]);

export default routes;
