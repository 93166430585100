import { lazy } from "react";

const Admin = lazy(() => import("./Admin"));
const About = lazy(() => import("./About"));

const adminRoutes = [
  {
    path: "/admin",
    element: <Admin />,
  },
  {
    path: "/about",
    element: <About />,
  },
];

export default adminRoutes;
