import Mock from "../mock";

const contact = {
    list: [
        {
            id: 1,
            department: "Accounts",
            name: "Jessica Gorsline",
            img_path: "/assets/images/faces/16.jpg",
            contact_link: "",
        },
        {
            id: 2,
            department: "Guest Services",
            name: "Lisa Allen",
            img_path: "/assets/images/faces/16.jpg",
            contact_link: "",
        },
        {
            id: 3,
            department: "Reservations",
            name: "Michelle Alvarado",
            img_path: "/assets/images/faces/16.jpg",
            contact_link: "",
        },
    ]
};

Mock.onGet("/api/vdcontacts").reply(config => {
  const response = contact.list;
  return [200, response];
});
