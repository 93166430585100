import GullLayout from "app/GullLayout/GullLayout";
import { useState, useEffect } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import localStorageService from "../services/localStorageService";

const AuthGuard = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(localStorageService.getItem("auth_user"));

  const { pathname } = useLocation();
  const [previouseRoute, setPreviousRoute] = useState(null);

  useEffect(() => {
    if (previouseRoute !== null) setPreviousRoute(pathname);
  }, [pathname, previouseRoute]);

  return isAuthenticated ? (
    <GullLayout>
      <Outlet />
    </GullLayout>
  ) : (
    <Navigate to="/signin" />
  );
};

export default AuthGuard;
