/*
temp_min //min current temperature in the city
temp_max //max current temperature in the city
*/

import { createSlice } from "@reduxjs/toolkit";

const weatherSlice = createSlice({
  name: "weatherSlice",
  initialState: {
    weather: {},
    city: "Orlando",
    temp: 0,
    feels_like: 0,
    humidity: 0,
    pressure: 0,
    temp_max: 0,
    temp_min: 0,
    icon: "01d",
    description: "clear sky",
    main: "Clear",
    visibility: 0,
    wind_speed: 0,
    wind_deg: 0,
    wind_direction: "",
    wind_gust: null,
    lon: 0,
    lat: 0,
    clouds: null,
    rain: [],
    rain1h: null,
    rain3h: null,
    snow: [],
    snow1h: null,
    snow3h: null,
    type: null,
    message: "",
    country: "",
    sunrise: null,
    sunset: null,
  },
  reducers: {
    setWeather: (state, action) => {
      state.weather     = action.payload.weather;
      state.city        = state.weather.name;
      state.temp        = state.weather.main.temp;
      state.feels_like  = state.weather.main.feels_like;
      state.humidity    = state.weather.main.humidity;
      state.pressure    = state.weather.main.pressure;
      state.temp_min    = state.weather.main.temp_min;
      state.temp_max    = state.weather.main.temp_max;
      state.icon        = state.weather.weather[0].icon;
      state.description = state.weather.weather[0].description;
      state.main        = state.weather.weather[0].main;
      state.visibility  = state.weather.visibility;
      state.wind_speed  = state.weather.wind.speed;
      state.wind_deg    = state.weather.wind.deg;
      state.wind_gust   = state.weather.wind.gust;
      state.lon         = state.weather.coord.lon;
      state.lat         = state.weather.coord.lat;
      state.clouds      = state.weather.clouds.all;

      state.rain        = state.weather.rain;
      state.rain1h      = state.weather.rain ? state.weather.rain['1h'] : 0;
      state.rain3h      = state.weather.rain ? state.weather.rain['3h']: 0;

      state.snow        = state.weather.snow;
      state.snow1h      = state.weather.snow ? state.weather.snow['1h'] : 0;
      state.snow3h      = state.weather.snow ? state.weather.snow['3h'] : 0;
      state.type        = state.weather.sys.type;
      state.message     = state.weather.sys.message;
      state.country     = state.weather.sys.country;
      state.sunrise     = state.weather.sys.sunrise;
      state.sunset      = state.weather.sys.sunset;
    },
  },
});

export const weatherActions = weatherSlice.actions;

export default weatherSlice;
