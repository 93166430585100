import { createSlice } from "@reduxjs/toolkit";
import { parseJSONWithErrorHandler } from '../../views/app/AppFunctions';

const accountSlice = createSlice({
  name: "accountSlice",
  initialState: {
    user_id: null,
    curbalance: null,
    ops_laststatement_json: [],
    ops_laststatement_futurerevenue: [],
    ops_laststatement_html: [],
    ops_laststatement_curbalance: [],
    ops_laststatement_periodbalance: [],
    ops_laststatement_futurebookings: [],
    ops_laststatement_guestbookings: [],
    ops_laststatement_ownerbookings: [],
    ops_laststatement_net_due: [],
    ops_running_year_reservation_income: [],
    ops_curbalance: [],
    changed: false,
  },
  reducers: {
    setCurbalance: (state, action) => {
      state.user = action.payload.user;
    },
    getStatementCurrent: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
    },
    getStatementMonthly: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
    },
    getStatementAnnual: (state) => {
      //
    },
    setOpsLaststatementjson: (state, action) => {
      // this is called after pulling the period json file

      if (action.payload.owner_property_id) {
        const existingItem = state.ops_laststatement_json.find((item) => item.owner_property_id === action.payload.owner_property_id);

        if (!existingItem) {
          const json_data           = parseJSONWithErrorHandler(action.payload.json);
          const future_guestbooking = json_data.future_guestbooking;
          const html                = json_data.html;
          const curbalance          = json_data.current_balance;
          const periodbalance       = json_data.period_balance;
          const futurebookings      = html.futurebookings;
          const guestbookings       = html.guestbookings;
          const ownerbookings       = html.ownerbookings;
          const net_due             = json_data.net_due;

          state.ops_laststatement_json.push({owner_property_id: action.payload.owner_property_id, json: action.payload.json});
          state.ops_laststatement_futurerevenue.push({owner_property_id: action.payload.owner_property_id, revenue: future_guestbooking});
          state.ops_laststatement_html.push({owner_property_id: action.payload.owner_property_id, html: html});
          state.ops_laststatement_curbalance.push({owner_property_id: action.payload.owner_property_id, curbalance: curbalance});
          state.ops_laststatement_periodbalance.push({owner_property_id: action.payload.owner_property_id, periodbalance: periodbalance});
          state.ops_laststatement_futurebookings.push({owner_property_id: action.payload.owner_property_id, futurebookings: futurebookings});
          state.ops_laststatement_guestbookings.push({owner_property_id: action.payload.owner_property_id, guestbookings: guestbookings});
          state.ops_laststatement_ownerbookings.push({owner_property_id: action.payload.owner_property_id, ownerbookings: ownerbookings});
          state.ops_laststatement_net_due.push({owner_property_id: action.payload.owner_property_id, net_due: net_due});
        };
      };
    },
    setOpsRunningYearReservationIncome: (state, action) => {
      if (action.payload.owner_property_id) {
        const existingItem = state.ops_running_year_reservation_income.find((item) => item.owner_property_id === action.payload.owner_property_id);

        if (!existingItem) {
          state.ops_running_year_reservation_income.push({owner_property_id: action.payload.owner_property_id, date_start: action.payload.date_start, date_end: action.payload.date_end, total: action.payload.total});
        }
      }
    },
  },
});

export const accountActions = accountSlice.actions;

export default accountSlice;

/*
A LOT OF THE ACCOUNT DATA IS FOUND IN THE PROPERTYSLICE PROPERTIES.
SEE OWNER_PROPERTIES.JSON IN MY DOWNLOADS DIR
*/
