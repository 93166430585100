import { createSlice } from "@reduxjs/toolkit";
import { parseJSONWithErrorHandler } from '../../views/app/AppFunctions';

const propertySlice = createSlice({
  name: "propertySlice",
  initialState: {
    properties: [],
    properties_ref: [],
    properties_name: [],
    properties_images: [],
    properties_bookings: [],
    properties_bookings_current: [],
    properties_vwbookings: [],
    properties_occupancy_previous_year: [],
    properties_periods: [],
    properties_owner_property_ids: [],
    webquotes: [],
    sub_menu: [],
    selected_property: [],
    changed: false,
  },
  reducers: {
    getOwnerProperties: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
    },
    getProperties: (state) => {
      return; //state.properties = state.properties;
    },
    setProperties: (state, action) => {
      state.properties = action.payload.properties;
      if (state.properties) {
        state.properties_ref = [];
        state.properties_name = [];
        state.properties_owner_property_ids = [];

        const properties = parseJSONWithErrorHandler(state.properties);

        properties.map((property) => {
            state.properties_ref.push({property_id: property.property_id, propertyref: property.propertyref});
            state.properties_name.push({property_id: property.property_id, propertyref: property.propertyname, propertyname: property.property_name});
            state.properties_owner_property_ids.push({property_id: property.property_id, op_id: property.ownerprop_id, propertyref: property.propertyref, propertyname: property.property_name});
        });
      };
    },
    setSelectedProperty: (state, action) => {
      state.selected_property = action.payload.selected_property;
console.log('action.payload.selected_property', state.selected_property);
    },
    setSubMenu: (state, action) => {
      state.sub_menu = action.payload.sub_menu;
console.log('action.payload.sub_menu', state.sub_menu);
    },
    setPropertiesImagesFront: (state, action) => {
      if (action.payload.property_id) {
        const existingItem = state.properties_images.find((item) => item.property_id === action.payload.property_id);
        if (!existingItem) {
          state.properties_images.push({property_id: action.payload.property_id, images: action.payload.images});
        };
      };
    },
    setPropertiesBookings: (state, action) => {
      if (action.payload.property_id) {
        const existingItem = state.properties_bookings.find((item) => item.property_id === action.payload.property_id);
        if (!existingItem) {
          state.properties_bookings.push({property_id: action.payload.property_id, bookings: action.payload.bookings});
        };
      };
    },
    setPropertiesVwBookings: (state, action) => {
      if (action.payload.property_id) {
        const existingItem = state.properties_vwbookings.find((item) => item.property_id === action.payload.property_id);
        if (!existingItem) {
          state.properties_vwbookings.push({property_id: action.payload.property_id, bookings: action.payload.bookings});
        };
      };
    },
    setPropertiesBookingsCurrent: (state, action) => {
      if (action.payload.property_id) {
        const existingItem = state.properties_bookings_current.find((item) => item.property_id === action.payload.property_id);
        if (!existingItem) {
          state.properties_bookings_current.push({property_id: action.payload.property_id, previous: action.payload.previous, current: action.payload.current, next: action.payload.next});
        };
      };
    },
    setPropertiesOccupancyPreviousYear: (state, action) => {
      if (action.payload.property_id) {
        const existingItem = state.properties_occupancy_previous_year.find((item) => item.property_id === action.payload.property_id);
        if (!existingItem) {
          state.properties_occupancy_previous_year.push({
            property_id: action.payload.property_id,
            booking_nights: action.payload.booking_nights,
            checked_nights: action.payload.checked_nights,
            booked_percentage: action.payload.booked_percentage,
            date_first: action.payload.date_first,
            date_last: action.payload.date_last,
            type: action.payload.type
          });
        };
      };
    },
    setPropertiesPeriods: (state, action) => {
      if (action.payload.property_id) {
        const existingItem = state.properties_periods.find((item) => item.property_id === action.payload.property_id);
        if (!existingItem) {
          state.properties_periods.push({property_id: action.payload.property_id, owner_property_id: action.payload.owner_property_id, period: action.payload.period});
        };
      };
    },
    setWebquote: (state, action) => {
console.log('SLICE WEBQUOTE: ', action.payload);
      if (action.payload.webquote_id) {
        const existingItem = state.webquotes.find((item) => item.webquote_id === action.payload.webquote_id);
        if (!existingItem) {
          if (Number(action.payload.webquote_id))
            state.webquotes.push({webquote_id: action.payload.webquote_id, webquote: action.payload.webquote, webquote_items: action.payload.webquote_items});
        };
console.log('SLICE WEBQUOTE STATE: ', state.webquotes);
      };
    },
    updateBookings: (state, action) => {

// this is not done and not in use, though it should be better than what we are using which is reloading the bookings each time a booking is cancelled
      // action.payload: property_id, booking_id, field, value
      if (action.payload.property_id) {
console.log('STATE PID: ', action.payload.property_id);
console.log('STATE BEFORE ALL BOOKINGS: ', state.properties_bookings);
// console.log('STATE BEFORE PID BOOKINGS: ', state.properties_bookings[action.payload.property_id]);
// console.log('STATE BEFORE PID BOOKINGS CUR: ', state.properties_bookings_current[action.payload.property_id]);

if (0 < state.properties_bookings.length) {
    const bookings = state.properties_bookings.find((item) => item.property_id === action.payload.property_id);

console.log('STATE BEFORE BOOKINGS FIND: ', bookings);

    if(0 < bookings.length) {
        bookings.map((bk) => {
    console.log("BOOKING: ", bk);
        });
    }
}


        // let bookingscur = state.properties_bookings_current[action.payload.property_id];

//         const existingBookings = bookings.find((item) => item.property_id === action.payload.property_id);
//         if (existingBookings) {
//             existingBookings.map((b, index) => {
//                 if (b.booking_id === action.payload.booking_id) {
//                     state.properties_bookings[action.payload.property_id][index][action.payload.field] = action.payload.value;
//                 }
//             });
//         };
// 
//         const existingBookingsCur = bookingscur.find((item) => item.property_id === action.payload.property_id);
//         if (existingBookingsCur) {
//             existingBookingsCur.map((b, index) => {
//                 if (b.booking_id === action.payload.booking_id) {
//                     state.properties_bookings_current[action.payload.property_id][index][action.payload.field] = action.payload.value;
//                 }
//             });
//         };
      };
// console.log('STATE AFTER PID BOOKINGS: ', state.properties_bookings[action.payload.property_id]);
// console.log('STATE AFTER PID BOOKINGS CUR: ', state.properties_bookings_current[action.payload.property_id]);
    },
  },
});

export const propertyActions = propertySlice.actions;

export default propertySlice;
