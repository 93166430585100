import axios from "axios";
import history from "@history.js";
import localStorageService from "./localStorageService";
import { setUserData, userLoggedIn, resetPasswordStatus, setLoginStatus } from "../redux/auth/authSlice";
import { useDispatch } from 'react-redux';

//https://stackoverflow.com/questions/62800170/alternative-for-usedispatch-hook-of-react-redux-for-class-component-in-react
import { store } from "../store"; // because this is a class...

import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "./CognitoUserPool";
import { notify } from "./notificationService";

class JwtAuthService {
  user = {};

  loginWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {

      const Username = email;
      const Password = password;

      const user_cognito = new CognitoUser({ Username, Pool });

      const authDetails = new AuthenticationDetails({ Username, Password });

      user_cognito.authenticateUser(authDetails, {
        onSuccess: (data) => {
          this.user = {
            userId: data.idToken.payload['custom:userbase.id'],
            userName: data.idToken.payload['cognito:username'],
            userNamePreferred: data.idToken.payload.preferred_username,
            role: 'OWNER',
            displayName: data.idToken.payload.given_name + ' ' + data.idToken.payload.family_name,
            email: data.idToken.payload.email,
            phone: data.idToken.payload.phone_number,
            photoUrl: data.idToken.payload.picture,
            age: null,
            token: data.idToken.jwtToken
          };

          store.dispatch(setUserData(this.user)); // because this is a class...
          store.dispatch(userLoggedIn({ accessToken: data.idToken.jwtToken, user: this.user })); // because this is a class...
          store.dispatch(setLoginStatus({loginStatus: 'success'}));

          localStorageService.setItem("auth_user", {
            accessToken: this.user.token,
            user: this.user,
          });

          this.setSession(data.idToken.jwtToken);
          this.setUser({ accessToken: data.idToken.jwtToken, user: this.user });

          history.push({ pathname: "/dashboard" });
          // hack. something is not right about how history.push is being used
          // downgrading history to 4.10 did not work
          window.location.href = window.location.href;

          resolve(this.user);
        }, // onSuccess
        onFailure: (err) => {
          console.error("authenticateUser err message: ", err.message);

          // PasswordResetRequiredException
          if ('PasswordResetRequiredException' === err.code) {
              store.dispatch(setLoginStatus({loginStatus: 'PasswordResetRequiredException'}));
console.log("err.code: ", err.code);
console.log("err.name: ", err.name);
console.log("err.message: ", err.message);
console.log("Username: ", Username);
console.log('Need to call forgotPassword');

// this call actually sent the code:
// user.forgotPassword(Username);

// if we hit this error block on trying to log in, then tell them and offer
// a page for setting password for the first time? or just the reset password???


          } else {
            // error
            store.dispatch(setLoginStatus({loginStatus: 'failure', errorMessage: err.message}));

            return err;
          }

        }, // onFailure
        newPasswordRequired: (data) => {
          console.log("authenticateUser newPasswordRequired: ", data);
          store.dispatch(setLoginStatus({loginStatus: 'newPasswordRequired', errorMessage: data}));
          resolve(data);
        },
      });

      resolve("nothing here at the bottom of the options. probably already had success handled?");
    }); // return new Promise
  }; // loginWithEmailAndPassword

  loginWithToken = (stored_auth) => {

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (Object.keys(this.user).length !== 0) resolve(this.user);
        else resolve(stored_auth);
      }, 100);
    }).then(data => {
      this.setSession(data.accessToken);
      this.setUser(data);

      store.dispatch(userLoggedIn({user: data, accessToken: data.accessToken}));

      return data;
    });
  };

  logout = () => {
console.log("JWT SLICE");

    this.setSession(null);
    this.removeUser();
  };

  setSession = token => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  };

  removeUser = () => {
    localStorage.removeItem("auth_user");
  };


  resetPassword = (values) => {
    return new Promise((resolve, reject) => {

      const Username = values.email;
      const newPassword = values.newPassword;

      const userData = {
          Username: Username,
          Pool: Pool
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.forgotPassword({
          onSuccess: function (result) {
console.log('call result: ' + result);
          },
          onFailure: function(err) {
              alert(err);
          },
          inputVerificationCode() {
              var verificationCode = prompt('Please input verification code that was emailed to the address on file: ' ,'');
              // var newPassword = prompt('Enter new password ' ,'');
              // cognitoUser.confirmPassword(verificationCode, newPassword, this);

if (null === verificationCode) { // null means Cancel was clicked
    return false;
}

              return new Promise((resolve, reject) => {
                cognitoUser.confirmPassword(verificationCode, newPassword, {
                    onFailure(err) {
        console.log('failed');
                        store.dispatch(notify({type: 'failure', action: 'resetPassword', message: err }));
                        reject(err);
                    },
                    onSuccess() {
        console.log('worked');
                        store.dispatch(resetPasswordStatus({resetPasswordStatus: 'success'}));
                        resolve();
                    },
                });
            });
          }
      });
    }); // return Promise
  };
} // class

const jwtAuthService = new JwtAuthService();

export default jwtAuthService;
