import Mock from "../mock";

const Guestsource = {
  list: [
        {
            "id": 1,
            "pp_code": "BBP",
            "src_category": "PHN",
            "active": 1,
            "description": "Call centre (from call)"
        },
        {
            "id": 2,
            "pp_code": "block",
            "src_category": "UNK",
            "active": 1,
            "description": "Blocked for 'A' Booking"
        },
        {
            "id": 3,
            "pp_code": "internet",
            "src_category": "WEB",
            "active": 0,
            "description": "Stay-Usa Time Out"
        },
        {
            "id": 4,
            "pp_code": "OLB",
            "src_category": "WEB",
            "active": 1,
            "description": "On Line Booking"
        },
        {
            "id": 5,
            "pp_code": "owner",
            "src_category": "OWN",
            "active": 1,
            "description": "Owner"
        },
        {
            "id": 6,
            "pp_code": "QBP",
            "src_category": "PHN",
            "active": 1,
            "description": "Call centre (online quote)"
        },
        {
            "id": 7,
            "pp_code": "ref",
            "src_category": "UNK",
            "active": 0,
            "description": "Refferall"
        },
        {
            "id": 8,
            "pp_code": "ret",
            "src_category": "UNK",
            "active": 0,
            "description": "Returning Guest"
        },
        {
            "id": 9,
            "pp_code": "tr agt",
            "src_category": "AGT",
            "active": 1,
            "description": "Travel Agent"
        },
        {
            "id": 10,
            "pp_code": "www",
            "src_category": "WEB",
            "active": 1,
            "description": "Villadirect.com"
        },
        {
            "id": 11,
            "pp_code": "wo",
            "src_category": "UNK",
            "active": 0,
            "description": "Work Order"
        },
        {
            "id": 12,
            "pp_code": "VDVEN",
            "src_category": "",
            "active": 1,
            "description": "Vendor (VillaDirect)"
        },
        {
            "id": 13,
            "pp_code": "AAA/CAA",
            "src_category": "UNK",
            "active": 0,
            "description": "AAA Member"
        },
        {
            "id": 14,
            "pp_code": "FLRO",
            "src_category": "UNK",
            "active": 0,
            "description": "Florida Resident"
        },
        {
            "id": 15,
            "pp_code": "TAX",
            "src_category": "UNK",
            "active": 0,
            "description": "Tax Refund"
        },
        {
            "id": 16,
            "pp_code": "CASH",
            "src_category": "UNK",
            "active": 0,
            "description": "5% Cash Back"
        },
        {
            "id": 17,
            "pp_code": "Milt",
            "src_category": "UNK",
            "active": 0,
            "description": "Military"
        },
        {
            "id": 18,
            "pp_code": "AirTran",
            "src_category": "UNK",
            "active": 0,
            "description": "AirTran Guests"
        },
        {
            "id": 19,
            "pp_code": "ATPAR",
            "src_category": "UNK",
            "active": 0,
            "description": "Canadian at Par"
        },
        {
            "id": 20,
            "pp_code": "Fuel",
            "src_category": "UNK",
            "active": 0,
            "description": "$50 RaceTrac gift card"
        },
        {
            "id": 21,
            "pp_code": "Trky1",
            "src_category": "",
            "active": 0,
            "description": "Free Turkey"
        },
        {
            "id": 22,
            "pp_code": "2 Day",
            "src_category": "",
            "active": 0,
            "description": "2 Day Special"
        },
        {
            "id": 23,
            "pp_code": "ZZ_BBP",
            "src_category": "",
            "active": 0,
            "description": "Spain - Booked by phone"
        },
        {
            "id": 24,
            "pp_code": "HBDS",
            "src_category": "",
            "active": 1,
            "description": "HotelBeds"
        },
        {
            "id": 25,
            "pp_code": "WUSA",
            "src_category": "",
            "active": 0,
            "description": "Welcome USA"
        },
        {
            "id": 26,
            "pp_code": "PAINT",
            "src_category": "",
            "active": 0,
            "description": "Paint Ball Group"
        },
        {
            "id": 27,
            "pp_code": "RETURN",
            "src_category": "",
            "active": 0,
            "description": "Returning Guest"
        },
        {
            "id": 28,
            "pp_code": "FREENIGH",
            "src_category": "",
            "active": 0,
            "description": "Free Night Promotion"
        },
        {
            "id": 29,
            "pp_code": "STAY-USA",
            "src_category": "",
            "active": 0,
            "description": "Stay-Usa Time Out"
        },
        {
            "id": 30,
            "pp_code": "LAYAWAY1",
            "src_category": "",
            "active": 0,
            "description": "Lay-A-Way Plan (Weekly Payments)"
        },
        {
            "id": 31,
            "pp_code": "LAYAWAY2",
            "src_category": "",
            "active": 0,
            "description": "Lay-A-Way Plan (Bi-Weekly Payments)"
        },
        {
            "id": 32,
            "pp_code": "LAYAWAY3",
            "src_category": "",
            "active": 0,
            "description": "Lay-A-Way Plan (Monthly Payments)"
        },
        {
            "id": 33,
            "pp_code": "FLIPKEY",
            "src_category": "",
            "active": 0,
            "description": "FlipKey"
        },
        {
            "id": 34,
            "pp_code": "GROUP",
            "src_category": "",
            "active": 0,
            "description": "Group Booking"
        },
        {
            "id": 35,
            "pp_code": "VIP/GS",
            "src_category": "",
            "active": 0,
            "description": "GS Recovery/VIP"
        },
        {
            "id": 36,
            "pp_code": "HOTOCT",
            "src_category": "",
            "active": 0,
            "description": "Red Hot October Promotion"
        },
        {
            "id": 37,
            "pp_code": "REDHOTOC",
            "src_category": "",
            "active": 0,
            "description": "Red Hot October Promotion"
        },
        {
            "id": 38,
            "pp_code": "WALK-IN",
            "src_category": "",
            "active": 1,
            "description": "Walk-In"
        },
        {
            "id": 39,
            "pp_code": "RETAIL-R",
            "src_category": "",
            "active": 0,
            "description": "Retail Returning Guest"
        },
        {
            "id": 40,
            "pp_code": "PRE-BOOK",
            "src_category": "",
            "active": 0,
            "description": "Pre-Booked"
        },
        {
            "id": 41,
            "pp_code": "RETG-WG",
            "src_category": "",
            "active": 0,
            "description": "Returning Guest (Winter Getaway 20%)"
        },
        {
            "id": 42,
            "pp_code": "TOUR/OP",
            "src_category": "",
            "active": 1,
            "description": "Tour Operator"
        },
        {
            "id": 43,
            "pp_code": "WC",
            "src_category": "",
            "active": 1,
            "description": "Web Chat"
        },
        {
            "id": 44,
            "pp_code": "FAMU",
            "src_category": "",
            "active": 0,
            "description": "FAMU - Florida Classic"
        },
        {
            "id": 45,
            "pp_code": "MIFI",
            "src_category": "",
            "active": 0,
            "description": "MiFi Rental"
        },
        {
            "id": 46,
            "pp_code": "FAM",
            "src_category": "",
            "active": 0,
            "description": "Familiarization Booking"
        },
        {
            "id": 47,
            "pp_code": "FB",
            "src_category": "",
            "active": 0,
            "description": "Facebook"
        },
        {
            "id": 48,
            "pp_code": "VKFRFO12",
            "src_category": "",
            "active": 0,
            "description": "VisitKissimmee Florida Resident Fall Offer 2012"
        },
        {
            "id": 49,
            "pp_code": "VKNYRFO1",
            "src_category": "",
            "active": 0,
            "description": "VisitKissimee New York Resident Fall Offer 2012"
        },
        {
            "id": 50,
            "pp_code": "LT",
            "src_category": "",
            "active": 0,
            "description": "Long Term Rental"
        },
        {
            "id": 51,
            "pp_code": "NLFALL12",
            "src_category": "",
            "active": 0,
            "description": "Newsletter Fall 2012"
        },
        {
            "id": 52,
            "pp_code": "TOWS",
            "src_category": "",
            "active": 0,
            "description": "Online Travel Agent"
        },
        {
            "id": 53,
            "pp_code": "HAVRBO",
            "src_category": "",
            "active": 1,
            "description": "VRBO"
        },
        {
            "id": 54,
            "pp_code": "HAHA",
            "src_category": "",
            "active": 1,
            "description": "HomeAway"
        },
        {
            "id": 55,
            "pp_code": "LENNARCG",
            "src_category": "",
            "active": 0,
            "description": "LENNAR Championsgate"
        },
        {
            "id": 56,
            "pp_code": "DWELL",
            "src_category": "",
            "active": 0,
            "description": "Dwellable"
        },
        {
            "id": 57,
            "pp_code": "AIRBNB",
            "src_category": "",
            "active": 1,
            "description": "AirBnB"
        },
        {
            "id": 58,
            "pp_code": "RED",
            "src_category": "",
            "active": 0,
            "description": "Red Awning"
        },
        {
            "id": 59,
            "pp_code": "CNSTAY",
            "src_category": "",
            "active": 0,
            "description": "Canada Stay"
        },
        {
            "id": 60,
            "pp_code": "SBS",
            "src_category": "",
            "active": 0,
            "description": "Spring Break Sports"
        },
        {
            "id": 61,
            "pp_code": "VCH",
            "src_category": "",
            "active": 0,
            "description": "VaycayHero.com"
        },
        {
            "id": 62,
            "pp_code": "OVER",
            "src_category": "",
            "active": 0,
            "description": "Past Tour Bookings"
        },
        {
            "id": 63,
            "pp_code": "DUAL",
            "src_category": "",
            "active": 0,
            "description": "Property Move"
        },
        {
            "id": 64,
            "pp_code": "Housekee",
            "src_category": "",
            "active": 0,
            "description": "Housekeeping"
        },
        {
            "id": 65,
            "pp_code": "Maintena",
            "src_category": "",
            "active": 0,
            "description": "Maintenance"
        },
        {
            "id": 66,
            "pp_code": "Set-up",
            "src_category": "",
            "active": 0,
            "description": "Property Set-up"
        },
        {
            "id": 67,
            "pp_code": "Terminat",
            "src_category": "",
            "active": 0,
            "description": "Property Termination"
        },
        {
            "id": 68,
            "pp_code": "Realtor",
            "src_category": "",
            "active": 1,
            "description": "Realtor Showing"
        },
        {
            "id": 69,
            "pp_code": "Manage",
            "src_category": "",
            "active": 0,
            "description": "Management Only"
        },
        {
            "id": 70,
            "pp_code": "HSKP",
            "src_category": "",
            "active": 0,
            "description": "Housekeeping"
        },
        {
            "id": 71,
            "pp_code": "MAINT",
            "src_category": "",
            "active": 0,
            "description": "Maintenance"
        },
        {
            "id": 72,
            "pp_code": "SETUP",
            "src_category": "",
            "active": 0,
            "description": "Property Set-up"
        },
        {
            "id": 73,
            "pp_code": "TERM",
            "src_category": "",
            "active": 1,
            "description": "Property Termination"
        },
        {
            "id": 74,
            "pp_code": "AGENT",
            "src_category": "",
            "active": 0,
            "description": "Agent"
        },
        {
            "id": 75,
            "pp_code": "OWNVEN",
            "src_category": "",
            "active": 1,
            "description": "Vendor (owner)"
        },
        {
            "id": 76,
            "pp_code": "BOOKCOM",
            "src_category": "",
            "active": 1,
            "description": "Booking.com"
        },
        {
            "id": 77,
            "pp_code": "EXPEDIA",
            "src_category": "",
            "active": 1,
            "description": "Expedia Travel"
        },
    ]
};

Mock.onGet("/api/guestsource").reply(config => {
  const response = Guestsource.list;
  return [200, response];
});
