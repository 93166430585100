import history from "@history.js";
import { createSlice } from "@reduxjs/toolkit";
import jwtAuthService from "../../services/jwtAuthService";

const initialState = {
  isAuthenticated: false,
  accessToken: undefined,
  success: false,
  loading: false,
  error: {
    username: null,
    password: null,
  },
  errorMessage: null,
  resetPasswordStatus: null,
  loginStatus: null,
  user: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const user = action.payload;
      state = { ...state, ...user };

      if (user.accessToken) {
        state.isAuthenticated = true;
      };
    },

    userLoggedIn: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
      if (action.payload.accessToken) state.isAuthenticated = true;
    },

    userLoggedOut: (state) => {
      state.accessToken = undefined;
      state.user = undefined;
      state.isAuthenticated = false;
    },

    resetPassword: (state, action) => {
      state.success = true;
      state.loading = false;
      console.log(action.payload.email);
    },

    requestStart: (state, action) => {
      state.loading = true;
    },

    resetPasswordStatus: (state, action) => {
      state.resetPasswordStatus = action.payload.resetPasswordStatus;
    },

    setLoginStatus: (state, action) => {
      state.loginStatus = action.payload.loginStatus;
      if (action.payload.errorMessage) state.errorMessage = action.payload.errorMessage;
    },

    logoutJWTUser: (state) => {
      jwtAuthService.logout();

      history.push({ pathname: "/signin" });
      // hack. something is not right about how history.push is being used
      // downgrading history to 4.10 did not work
      window.location.href = window.location.href;

      state.user = undefined;
    },
  },
});

export const {
  setUserData,
  userLoggedIn,
  userLoggedOut,
  resetPassword,
  resetPasswordStatus,
  setLoginStatus,
  logoutJWTUser,
} = authSlice.actions;
export default authSlice.reducer;
