import "../fake-db";
import "../data";
import "../styles/app/app.scss";
import history from "@history.js";
import Auth from "./auth/Auth";
import React from "react";
import routes from "./routes";
import { RouterProvider } from "react-router-dom";
import localStorageService from "./services/localStorageService";

function App() {

  let environment = 'dev';
  let host = window.location.host;
  let parts = host.split(".");
  let subdomain = "";

  subdomain = parts[0];
console.log('subdomain: ', subdomain);

  switch (subdomain) {
    case "staging":
      environment = 'staging';

      break;

    default:
      // prod might be www or naked domain
      if(subdomain.indexOf("localhost") === -1) {
        environment = 'prod';
      }

      break;
  }

  localStorageService.setItem("environment", environment);

  return (
    <Auth>
      <RouterProvider router={routes} history={history} />
    </Auth>
  );
}

export default App;
