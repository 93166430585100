import { Popover } from "react-bootstrap";

export const version_number = '0.0.24';
export const app_title      = 'VillaDirect Owner Portal';

export const logo_path             = 'https://d3r7sbsmd7qxqf.cloudfront.net/owner-portal-logo.gif';
export const favicon_logo_path     = 'https://d3r7sbsmd7qxqf.cloudfront.net/vdo-favicon-logo.gif';
export const image_base_url        = 'https://s3-us-west-2.amazonaws.com/vd-property-assets/PropertyAssets/';
export const vdcom_property_url    = 'https://www.villadirect.com/view_property.php?prpID=';
export const tooltip_lost_password = '<p>Enter your username and new password then click Reset Password.</p><p>A verification code will be sent to the email on file for the username. Input the code and click OK.</p>';
export const openweather_icon_path = 'https://openweathermap.org/img/wn/';
export const openweather_icon_filetype = '.png';

export const hpa_to_inhg_conversion = 0.0295; // hPa * this value = inches (inHg)

export const popover_lost_password = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Forgot/Reset Password</Popover.Header>
      <Popover.Body>
        {/* {tooltip_lost_password} */}
        <p>Enter your username and new password then click Reset Password.</p><p>A verification code will be sent to the email on file for the username. Input the code into the popup and click OK.</p>
      </Popover.Body>
    </Popover>
);

export const popover_first_signin = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">First time signing in</Popover.Header>
      <Popover.Body>
        <p>If you are signing in for the first time you will need to create a password.</p>
        <ul>
          <li>Click the "Signing in for the first time?" link to go to the Forgot/Reset Password page</li>
          <li>Enter your username or email address on file.</li>
          <li>Enter a password. The password must contain upper and lower case letters, at least one number and a symbol, and must be at least 8 characters in length.</li>
          <li>Click the Reset Password button</li>
          <li>A verification code will be sent to the email on file for the username.</li>
          <li>Input the code into the popup and click OK.</li>
        </ul>
      </Popover.Body>
    </Popover>
);

export const owner_booking_types = [
    { id: 3, code: 'OWNER', description: 'Owner'},
    { id: 4, code: 'OWNER-GUEST', description: 'Owner Guest'},
];

export const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
