import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserData } from "../redux/auth/authSlice";
import jwtAuthService from "../services/jwtAuthService";
import localStorageService from "../services/localStorageService";

const Auth = ({ children }) => {
  const dispatch = useDispatch();
  const checkJwtAuth = (stored_auth) => {
    jwtAuthService.loginWithToken(stored_auth).then((user) => {
      dispatch(setUserData(user));
    });
  };

  useEffect(() => {
    const stored_auth = localStorageService.getItem("auth_user");

    try {
      if (null !== stored_auth.accessToken) {
        dispatch(setUserData(stored_auth));
        checkJwtAuth(stored_auth);
      }
    } catch (error) {
      console.log("Auth:useEffect stored_auth.accessToken null or undefined. Error was: "+error);
    }
  }, []);

  return <>{children}</>;
};

export default Auth;
