import { createSlice } from "@reduxjs/toolkit";

const bookingSlice = createSlice({
  name: "bookingSlice",
  initialState: {
    bookingtypes: [],
    bookings_arrivalpack: [],
    booking_was_cancelled: false,
    booking_cancelled: 0,
    force_refresh_booking_list: false,
    force_refresh_booking_list_widget: false,
    changed: false,
  },
  reducers: {
    setBookingtypes: (state, action) => { // not in use. using data/db/bookingtypes.js
      state.bookingtypes = action.payload;
    },
    setBookingsArrivalPack: (state, action) => {
      if (action.payload.booking_id) {
        const existingItem = state.bookings_arrivalpack.find((item) => item.booking_id === action.payload.booking_id);
        if (!existingItem) {
          state.bookings_arrivalpack.push({booking_id: action.payload.booking_id, html: action.payload.html, vars: action.payload.vars});
        };
      }
    },
    setBookingCancelled: (state, action) => {
      if (action.payload.booking_id) {
        state.booking_was_cancelled = true;
        state.booking_cancelled     = action.payload.booking_id;
        state.force_refresh_booking_list = true;
        state.force_refresh_booking_list_widget = true;
      }
    },
    resetBookingCancelled: (state, action) => {
      if (action.payload.booking_id) {
        if (action.payload.booking_id === state.booking_cancelled) {
          state.booking_was_cancelled = false;
          state.booking_cancelled     = action.payload.booking_id;
        }
      }
    },
    resetForceRefreshBookingList: (state, action) => {
        state.force_refresh_booking_list = false;
      },
    resetForceRefreshBookingListWidget: (state, action) => {
        state.force_refresh_booking_list_widget = false;
      },
    },
});

export const bookingActions = bookingSlice.actions;

export default bookingSlice;
