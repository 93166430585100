import { lazy } from "react";

const Account = lazy(() => import("./Account"));
const AccountStatementMonthly = lazy(() => import("./AccountStatementMonthly"));
const AccountStatementAnnual = lazy(() => import("./AccountStatementAnnual"));
const ViewLastStatement = lazy(() => import("./ViewLastStatement"));
const FutureBookingList = lazy(() => import("./FutureBookingList"));
const MakePayment = lazy(() => import("./MakePayment"));

const accountRoutes = [
  {
    path: "/account",
    element: <Account />,
  },
  {
    path: "/account/statementmonthly",
    element: <AccountStatementMonthly />,
  },
  {
    path: "/account/statementannual",
    element: <AccountStatementAnnual />,
  },
  {
    path: "/account/laststatement/view",
    element: <ViewLastStatement />,
  },
  {
    path: "/account/futurebooking/list",
    element: <FutureBookingList />,
  },
  {
    path: "/account/payment/create",
    element: <MakePayment />,
  },
];

export default accountRoutes;
