import { lazy } from "react";

const MaintenanceList = lazy(() => import("./MaintenanceList"));
const MaintenanceView = lazy(() => import("./MaintenanceView"));
const MaintenanceEdit = lazy(() => import("./MaintenanceEdit"));

const maintenanceRoutes = [
  {
    path: "/maintenance/list",
    element: <MaintenanceList />,
  },
  {
    path: "/maintenance/view",
    element: <MaintenanceView />,
  },
  {
    path: "/maintenance/edit",
    element: <MaintenanceEdit />,
  },
];

export default maintenanceRoutes;
