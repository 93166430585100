import Mock from "../mock";

const Bookingtypes = {
  list: [
        {
            "id": 1,
            "type": "BLOC",
            "title": "Multimarket block",
            "color": "#CC99FF"
        },
        {
            "id": 2,
            "type": "BOOK",
            "title": "Booked - VillaDirect",
            "color": "#309c30"
        },
        {
            "id": 3,
            "type": "NONT",
            "title": "Non Taxable Reservations",
            "color": "#FFF"
        },
        {
            "id": 4,
            "type": "OB",
            "title": "Owner guest",
            "color": "#e580e2"
        },
        {
            "id": 5,
            "type": "OBCC",
            "title": "Owner booking chargeable/cr cd",
            "color": "#0000CC"
        },
        {
            "id": 6,
            "type": "OBT",
            "title": "Owner Booking- Tentative",
            "color": "#8245FF"
        },
        {
            "id": 7,
            "type": "OBTD",
            "title": "Owner Booking Tax Due",
            "color": "#FF66FF"
        },
        {
            "id": 8,
            "type": "OG",
            "title": "Owner Guest",
            "color": "#FFF"
        },
        {
            "id": 9,
            "type": "OWN",
            "title": "Owner stay",
            "color": "#660ad6"
        },
        {
            "id": 10,
            "type": "SPRI",
            "title": "Spring Clean",
            "color": "#FF0000"
        },
        {
            "id": 11,
            "type": "SS",
            "title": "Old Type Not Used",
            "color": "#FFF"
        },
        {
            "id": 12,
            "type": "TENT",
            "title": "Tentative",
            "color": "#FFFF00"
        },
        {
            "id": 13,
            "type": "TRAG",
            "title": "Travel Agent-no deposit",
            "color": "#FFF"
        },
        {
            "id": 14,
            "type": "TXEM",
            "title": "Tax Exempt - Booked",
            "color": "#309c30"
        },
        {
            "id": 15,
            "type": "UDEP",
            "title": "Deposit - VillaDirect",
            "color": "#abc497"
        },
        {
            "id": 16,
            "type": "UDSS",
            "title": "Old Type Not Used",
            "color": "#FFF"
        },
        {
            "id": 17,
            "type": "VALU",
            "title": "Value Villa",
            "color": "#999999"
        },
        {
            "id": 18,
            "type": "WO",
            "title": "Work Order",
            "color": "#997656"
        },
        {
            "id": 19,
            "type": "SHDW",
            "title": "Shadow Booking",
            "color": "#CCCCCC"
        },
        {
            "id": 20,
            "type": "ZMIF",
            "title": "MiFi Rental",
            "color": "#CEFF00"
        },
        {
            "id": 21,
            "type": "MIFI",
            "title": "MiFi Rental",
            "color": "#94BDBD"
        },
        {
            "id": 22,
            "type": "FAM",
            "title": "Familiarization",
            "color": "#1BE098"
        },
        {
            "id": 23,
            "type": "REAL",
            "title": "Realtor",
            "color": "#474747"
        },
        {
            "id": 24,
            "type": "LT",
            "title": "Long Term",
            "color": "#6605cc"
        },
        {
            "id": 25,
            "type": "SSBK",
            "title": "Short Stay - Booked",
            "color": "#999999"
        },
        {
            "id": 26,
            "type": "OTA",
            "title": "Online Travel Agent",
            "color": "#aae4ba"
        },
        {
            "id": 27,
            "type": "AGG",
            "title": "Aggregator",
            "color": "#2c8424"
        },
        {
            "id": 28,
            "type": "POR",
            "title": "Portal",
            "color": "#f27021"
        },
        {
            "id": 29,
            "type": "CHNL",
            "title": "Channel Partners - Booked",
            "color": "#3e557e"
        },
        {
            "id": 30,
            "type": "BSS",
            "title": "booked short stay",
            "color": "#d42b73"
        },
        {
            "id": 31,
            "type": "BKCN",
            "title": "Construction Discount Booking",
            "color": "#108484"
        },
        {
            "id": 32,
            "type": "PLAY",
            "title": "Stay and Play Promotion",
            "color": "#143dc2"
        },
        {
            "id": 34,
            "type": "DUAL",
            "title": "Dual Marketing",
            "color": "#f4ac48"
        },
        {
            "id": 35,
            "type": "CHUD",
            "title": "Channel Partners - Deposit",
            "color": "#c3d7f4"
        },
        {
            "id": 36,
            "type": "TXUD",
            "title": "Tax Exempt - Deposit",
            "color": "#abc497"
        },
        {
            "id": 37,
            "type": "SSUD",
            "title": "Short Stay - Deposit",
            "color": "#b0b0b0"
        },
        {
            "id": 38,
            "type": "LY1D",
            "title": "Layaway Bi-weekly - Deposit",
            "color": "#abc497"
        },
        {
            "id": 39,
            "type": "LY1B",
            "title": "Layaway Bi-weekly - Booked",
            "color": "#309c30"
        },
        {
            "id": 40,
            "type": "LY2D",
            "title": "Layaway Monthly - Deposit",
            "color": "#abc497"
        },
        {
            "id": 41,
            "type": "LY2B",
            "title": "Layaway Monthly - Booked",
            "color": "#309c30"
        },
        {
            "id": 42,
            "type": "HOLD",
            "title": "Hold",
            "color": "#79fce8"
        },
        {
            "id": 43,
            "type": "CPOA",
            "title": "Channel -  Payment on Arrival",
            "color": "#c3d7f4"
        },
        {
            "id": 44,
            "type": "TERM",
            "title": "Terminated",
            "color": "#000000"
        }
    ]
};

Mock.onGet("/api/bookingtypes").reply(config => {
  const response = Bookingtypes.list;
  return [200, response];
});
