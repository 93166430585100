import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./redux/api/apiSlice";
import authSliceReducer from "./redux/auth/authSlice";
import layoutSliceReducer from "./redux/layout/layoutSlice";
import loginSliceReducer from "./redux/login/authSlice";
import propertySlice from "./redux/property/propertySlice";
import accountSlice from "./redux/account/accountSlice";
import bookingSlice from "./redux/booking/bookingSlice";
import weatherSlice from "./redux/weather/weatherSlice";
import notificationSlice from "./redux/notification/notificationSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSliceReducer,
    layout: layoutSliceReducer,
    login: loginSliceReducer,
    property: propertySlice.reducer,
    account: accountSlice.reducer,
    booking: bookingSlice.reducer,
    weather: weatherSlice.reducer,
    notification: notificationSlice.reducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(apiSlice.middleware),
});
